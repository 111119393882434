<template>
    <v-app>
        <authorized>
            <template #default>
                <ampifire-header />
                <setup-button v-show="canSetup" />
            </template>
            <template #fallback>
                <client-cabin-header v-if="authenticated" />
            </template>
        </authorized>
        <v-main v-if="authenticated" class="main-background">
            <system-messages class="py-0" />

            <slot></slot>
            <toast-notification />
        </v-main>
        <v-footer v-if="authenticated" color="pt-lg-10 pb-lg-6 grey-light">
            <client-cabin-footer />
        </v-footer>
        <cookie-consent v-if="authenticated" />
        <authorized>
            <setup />
        </authorized>
    </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { mapGetters } from 'vuex';

import { ProvideReactive } from '@/utils/decorators';
import { copy } from '@/utils/helpers';

import AmpifireHeader from '@/layouts/Ampifire/AmpifireHeader.vue';
import ClientCabinHeader from '@/layouts/ClientCabin/ClientCabinHeader.vue';
import ClientCabinFooter from '@/layouts/ClientCabin/ClientCabinFooter.vue';

import { CookieConsent } from '@/components/CookieConsent';
import { ToastNotification } from '@/components/ToastNotification';
import { SystemMessages } from '@/components/SystemMessages';

import { Authorized } from '@/components/Authorized';
import { Setup, SetupButton } from '@/components/ClientCabin/Setup';

import Analytics from '@/components/ClientCabin/Analytics/Analytics';
import { AnalyticsEvents } from '@/components/ClientCabin/Analytics/AnalyticsEvents';

import type {
    ClientCabin,
    ClientCabinAnalytics,
    ClientCabinOptions
} from '@/types/ClientCabin';

@Component({
    components: {
        AmpifireHeader,
        ClientCabinHeader,
        ClientCabinFooter,
        CookieConsent,
        ToastNotification,
        SystemMessages,
        Authorized,
        Setup,
        SetupButton
    },
    computed: {
        ...mapGetters('user', ['authenticated'])
    }
})
export default class ClientCabinLayout extends Vue {
    authenticated!: boolean;

    @ProvideReactive()
    endpoint = '/lead';

    @ProvideReactive()
    setupEndpoint = '/admin';

    @ProvideReactive()
    options: ClientCabin | null = null;

    @ProvideReactive()
    setMachineOptions(options: ClientCabinOptions) {
        const current = this.options || {};

        this.options = {
            ...current,
            options: copy(options)
        } as ClientCabin;
    }

    @ProvideReactive()
    defaults = {
        custom_headline_short:
            'Discover The Biggest & Best Traffic Source For Your Business',
        custom_headline_long: 'Organic Traffic Done-For-You',
        video_url: 'https://fast.wistia.net/embed/iframe/t2tctewwnj',
        sales_video_url: 'https://fast.wistia.net/embed/iframe/o8hgaookz5'
    };

    get canSetup() {
        return Boolean(this.$route.meta?.configurable);
    }

    get hasSubdomain() {
        return (
            typeof window.location.host
                .split('clientcabin.com')
                .filter(Boolean)[0] !== 'undefined'
        );
    }

    created() {
        if (this.hasSubdomain) {
            this.$http.get(this.endpoint).then(({ data }) => {
                if (data.data.redirect) {
                    this.redirect(data.data.redirect);
                } else {
                    this.setOptions(data.data);

                    this.initAnalytics(this.options?.options?.analytics);
                }
            });
        }
    }

    setOptions(options: ClientCabin) {
        this.options = options;
    }

    initAnalytics(options?: ClientCabinAnalytics) {
        if (options) {
            Analytics.init(options);
            Analytics.trackPage();
        }
    }

    @ProvideReactive()
    track(event: AnalyticsEvents) {
        Analytics.track(event);
    }

    redirect(url: string) {
        window.location.href = url;
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/clientcabin.theme.scss';
</style>
