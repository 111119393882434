import Amex from '@/components/Icons/Amex.vue';
import CbsNews from '@/components/Icons/CbsNews.vue';
import Fox from '@/components/Icons/Fox.vue';
import Mastercard from '@/components/Icons/Mastercard.vue';
import Msn from '@/components/Icons/Msn.vue';
import Nbc from '@/components/Icons/Nbc.vue';
import Paypal from '@/components/Icons/Paypal.vue';
import Pbj from '@/components/Icons/Pbj.vue';
import Secure from '@/components/Icons/Secure.vue';
import Visa from '@/components/Icons/Visa.vue';

export default {
    visa: {
        component: Visa
    },
    amex: {
        component: Amex
    },
    mastercard: {
        component: Mastercard
    },
    paypal: {
        component: Paypal
    },
    secure: {
        component: Secure
    },
    msn: {
        component: Msn
    },
    cbsnews: {
        component: CbsNews
    },
    fox: {
        component: Fox
    },
    nbc: {
        component: Nbc
    },
    pbj: {
        component: Pbj
    }
};
